<template>
    <AanmeldenLoggedOut :orderDetails="orderDetails" :roadtrip="roadtrip" />
</template>

<script>
import AanmeldenLoggedOut from '@/components/kalender/aanmelden-loggedout';
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import { OrderDetail } from '@/models/OrderDetail';

export default {
    name: 'RoadtripAanmeldenGast',
    components: 
    {
        AanmeldenLoggedOut
    },
    mixins: [FormatMixin, MenuHelperMixin, RoadtripMixin, MetaTagsMixin],
    data: () => ({
        roadtrip: {
        },
        orderDetails: new OrderDetail(),
    }),
    beforeCreate(){
        this.$store.dispatch('menuHelper/getRoadtripLabels');
        this.$store.dispatch('menuHelper/getFoodRestrictions');
        this.$store.dispatch('menuHelper/getVehicleOptions');
        this.$store.dispatch('menuHelper/getAccountOrigins');
        this.$store.dispatch('menuHelper/getCountries');
        this.$store.dispatch('menuHelper/getProductCategories');
    },
    mounted()
    {
        this.$store.commit('setFooterVisibility', false);
        const uniqueName = this.$route.params.trip;
        this.getRoadtripInformation(uniqueName);
    },
    metaInfo() {
        return {
        title: this.roadtrip.tripName ? `| Aanmelden gast | ${this.roadtrip.tripName}` : `| Aanmelden gast`,
        meta: [
            this.getKeywords(`${this.roadtrip.label}, ${this.roadtrip.tripName}`),
            this.getMetaDescription(`${this.roadtrip.label} roadtrip aanmelden gast`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.roadtrip.tripName}`, `${this.roadtrip.label} roadtrip aanmelden gast`,  '', ''),
        ]
        };
    },
    methods: 
    {
        getRoadtripInformation(uniqueName)
        {
            this.$store.dispatch('productModule/getPublicProductByName', uniqueName)
                .then(data => {
                this.roadtrip = this.mapRoadtripData(data);
                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
     
    }
}
</script>